import React, { useState, useEffect, useRef } from 'react'

import ChatSquareHero from '../ChatComponent/ChatSquareHero'
import DevelopementAllProjectDisplay from '../Portfolio/DevelopementAllProjectDisplay'
import RenderAllProjectDisplay from '../Rendering/RenderAllProjectDisplay'
import MusicAllProjectDisplay from '../Music/MusicAllProjectDisplay'

import chevron from '../../Images/chevron-right-white.svg'

const height_multiply = 0.5
const box_padding = 20
// const speed = 20
// const numOfSteps = 18

const data = [0,1,2,3]

const Carosel = ({width,dimensions,navigate}) => {
	const [ slide, setSlide ] = useState(0)
	const [ count, setCount ] = useState(0)

	const interval = useRef()
	useEffect(()=>{
		// let count = 0
		interval.current = setInterval(()=>{
			setSlide((count+1)%4)
			setCount(count+1)
		},4500)
		return()=>{
			clearInterval(interval.current)
		}
	},[count])


	function sliderClicked(num) {
		clearInterval(interval.current)
		setSlide(num)
		setCount(num)
	}




	let height = width*height_multiply
	if(width < 850) height = width*0.6
	if(width < 600) height = width*0.7
	if(width < 450) height = width*0.8
	let padding = 40
	if(width < 1000) padding = 20
	// if(width < 500) padding = 10


	function isCircleFilled(num) {
		if(num !== slide) return <div style={{padding:4}} className='pointer hoverOpacityUp' onClick={()=>{sliderClicked(num)}}><div style={{height:8,width:8,borderRadius:20,background:'rgb(90,90,90)'}}></div></div>
		return <div style={{padding:4}} className='pointer' onClick={()=>{sliderClicked(num)}}><div style={{height:8,width:8,borderRadius:20}} className='whiteBackground'></div></div>
	}

	function squareDisplay(itt) {
		if(itt === 0) return <ChatSquareHero width={width} height={height} padding={padding} navigate={navigate} />
		if(itt === 1) return <DevelopementAllProjectDisplay width={width} height={height} padding={padding} navigate={navigate} />
		if(itt === 2) return <RenderAllProjectDisplay width={width} height={height} padding={padding} navigate={navigate} />
		if(itt === 3) return <MusicAllProjectDisplay width={width} height={height} padding={padding} navigate={navigate} />
	}
	// function caroselSlider() {
	// 	let arr = []
	// 	for(let i = 0; i < 4; i++){
	// 		arr.push( <div key={i} style={{width:width+box_padding}} className='flexCenter carousel-item'>{squareDisplay(i)}</div> )
	// 	}
	// 	return <div className='flexLeft' style={{width:(width+box_padding)*4,marginLeft:((dimensions.width-width-box_padding)*0.5)-(slide*((dimensions.width)-(dimensions.width-width-box_padding)))}}>{arr}</div>
	// }

	let arrow_wide = dimensions.width
	if(arrow_wide > width + 60) arrow_wide = width+60
	return(
		<div style={{width:dimensions.width}} className='overflow fadeIn'>

			<div className='flexCenter absolute opacityUpFromZerox pointerNone' style={{width:dimensions.width,zIndex:1}}>
				<div className='flexCenter' style={{height:height,width:arrow_wide}}>
					<div className='halfWidth flexLeft'>
						{slide === 0
							? null
							: <div style={{height:40,width:40,borderRadius:20,pointerEvents:'auto'}} className='whiteBackgroundx ggl_border blackBackground pointer hoverOpacityUp flexCenter' onClick={()=>{sliderClicked(slide-1)}}>
								<img src={chevron} alt='chevron' style={{height:26,transform:'rotate(180deg)'}} />
								<div style={{width:2}}></div>
							</div>
						}
					</div>
					<div className='halfWidth flexRight'>
						{slide === 3
							? null
							: <div style={{height:40,width:40,borderRadius:20,pointerEvents:'auto'}} className='whiteBackgroundx ggl_border blackBackground pointer hoverOpacityUp flexCenter' onClick={()=>{sliderClicked(slide+1)}}>
								<div style={{width:2}}></div>
								<img src={chevron} alt='chevron' style={{height:26}} />
							</div>
						}
					</div>
				</div>
			</div>

			<div className='carousel-container' style={{width:(width+box_padding)*4,
					marginLeft:(dimensions.width-width-box_padding)*0.5,
					// marginLeft:((dimensions.width-width-box_padding)*0.5)-(slide*((dimensions.width)-(dimensions.width-width-box_padding)))
					}}>
				{/*{caroselSlider()}*/}
				{data.map((item, index) => {
					return <div className='carousel-item' style={{width:width+box_padding,
							transform:`translate(-${slide * 100}%)`,opacity:slide === index ? 1 : 0.3
							}} key={index}>
						{squareDisplay(index)}
					</div>
				})}
			</div>

			<div style={{height:12}}></div>

			<div className='flexCenter fadeInslow'>
				{isCircleFilled(0)}
				{isCircleFilled(1)}
				{isCircleFilled(2)}
				{isCircleFilled(3)}
			</div>

		</div>
	)
}

export default Carosel