import dance_cube_mobile from '../../Images/screen_shots_tinified/dance_cube_mobile2.jpg'
import lo_home_mobile from '../../Images/screen_shots_tinified/lo_home_mobile2.jpg'
import triktreat_mobile from '../../Images/screen_shots_tinified/triktreat_mobilex3.jpg'
import musicgte_mobile2 from '../../Images/screen_shots_tinified/musicgte_mobile3.jpg'
import tender_mobile4 from '../../Images/screen_shots_tinified/tender_mobile4.jpg'
// import fork_and_spoon_mobile from '../../Images/screen_shots/fork_and_spoon_mobile.png'
// import songhatch_mobile from '../../Images/screen_shots/songhatch_mobile.jpg'
import catalogue_mobile from '../../Images/screen_shots_tinified/catalogue_mobile.jpg'
import fknspn_mobile from '../../Images/screen_shots_tinified/fknspn_mobile.jpg'
import andy_mobile from '../../Images/screen_shots_tinified/andy_mobile_2.jpg'

// import songhatch_desktop from '../../Images/screen_shots/songhatch_desktop.png'
// import musicgte_desktop from '../../Images/screen_shots/musicgte_desktop.jpg'
// import triktreat_desktop from '../../Images/screen_shots/triktreat_desktop.jpg'
// import lo_home_desktop from '../../Images/screen_shots/lo_home_desktop.jpg'
// import dance_cube_desktop from '../../Images/screen_shots/dance_cube_desktop.jpg'

import catalogue_desktop from '../../Images/screen_shots_tinified/catalogue_desktop2.png'
import musicgte_desktop2 from '../../Images/screen_shots_tinified/musicgte_desktop2.png'
import triktreat_desktop2 from '../../Images/screen_shots_tinified/triktreat_desktop2.png'
import lo_home_desktop2 from '../../Images/screen_shots_tinified/lo_home_desktop2.png'
import dance_cube_desktop2 from '../../Images/screen_shots_tinified/dance_cube_desktop2.png'
import fknspn_desktop2 from '../../Images/screen_shots_tinified/fknspn_desktop2.png'
import andy_desktop from '../../Images/screen_shots_tinified/andy_desktop.png'
import tender_desktop1 from '../../Images/screen_shots_tinified/tender_desktop2.jpg'

// import catalogue_mobile_2 from '../../Images/screen_shots/catalogue_mobile_3.png'


const Portfolio = {
	'songhatch': {
		'name': 'SongHatch',
		'heading': `Find Your Sound with SongHatch!`,
		'subheading': `Generate, edit, and transform original music ideas effortlessly`,
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': musicgte_mobile2,
		'desktop': musicgte_desktop2,
	},
	'catalogue': {
		'name': 'Music catalogue',
		'heading': 'MusicCata: Your Soundtrack',
		'subheading': 'Explore unique, royalty-free music tailored for your content',
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': catalogue_mobile,
		'desktop': catalogue_desktop,
	},
	'andy': {
		'name': 'Andy Marsh Music',
		'heading': 'Andy Marsh Music',
		'subheading': 'A website to showcase the work of Andy Marsh',
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': andy_mobile,
		'desktop': andy_desktop,
	},
	'privichef': {
		'name': 'PriviChef',
		'heading': 'Dine in Style with PriviChef!',
		'subheading': 'Where gourmet meets home with a chef at your doorstep',
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': fknspn_mobile,
		'desktop': fknspn_desktop2,
	},
	'tender': {
		'name': 'Tender',
		'heading': 'Home Dreams Meet Build Teams!',
		'subheading': 'Swipe right and bring your dream home closer to reality',
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': tender_mobile4,
		'desktop': tender_desktop1,
	},
	'triktreat': {
		'name': 'TrikTreat',
		'heading': 'TrikTreat: Halloween Reimagined',
		'subheading': 'Escape lockdown with virtual trick-or-treating',
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': triktreat_mobile,
		'desktop': triktreat_desktop2,
	},
	'lohome': {
		'name': 'Lo-Home',
		'heading': 'Hidden Potential with Lo-Home!',
		'subheading': `Zillow's hidden treasures unlock potential opportunities`,
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': lo_home_mobile,
		'desktop': lo_home_desktop2,
	},
	'dancecube': {
		'name': 'Dance-Cube',
		'heading': `Dance like no one's watching!`,
		'subheading': 'Transform your moves into a fun 3D experiences',
		'banner': 'https://plus.unsplash.com/premium_photo-1701590725747-ac131d4dcffd?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		'mobile': dance_cube_mobile,
		'desktop': dance_cube_desktop2,
	},
}

export default Portfolio