import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
// import { database } from './firebase'
// import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore'
// import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import MainRoute from './Components/Routes/MainRoute'
import Portfolio from './Components/Data/Portfolio'

// EXAMPLE portfolios
// https://www.hostinger.com/tutorials/web-developer-portfolio


import './App.css'

const App = ({updateDimensions,changeMobile}) => {

	// HANDLES PAGE RESIZING
		useEffect(()=>{
			function init() {
				updateDimensions({height:document.documentElement.clientHeight,
								width:document.documentElement.clientWidth});
			}
			updateDimensions({height:document.documentElement.clientHeight,
							width:document.documentElement.clientWidth});
			window.addEventListener('resize', init);
			return () => {
				window.removeEventListener('resize', init);
			}
		},[updateDimensions]);

	// CHECK IF DEVICE IS MOBILE
		useEffect(()=>{
			const userAgent = navigator.userAgent.toLowerCase();
			const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
			if ( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) || isTablet === true ){
				changeMobile(true);
			}
		},[changeMobile]);

	const navigate = useNavigate()


	function navigateClicked(e) {
		window.scrollTo({ top: 0, left: 0})
		navigate(e)
	}


	function portfolioRoutes() {
		let array = []
		Object.keys(Portfolio).forEach(function(key,item){ array.push(<Route path={'/projects/'+key} key={key} element={<MainRoute route={'projects/'+key} navigate={navigateClicked} />} />) })
		return array
	}
	return(
		<Routes>
			<Route path='/' element={<MainRoute route={''} navigate={navigateClicked} />} />
			<Route path='/about' element={<MainRoute route={'about'} navigate={navigateClicked} />} />
			<Route path='/education' element={<MainRoute route={'education'} navigate={navigateClicked} />} />

			<Route path='/projects' element={<MainRoute route={'projects'} navigate={navigateClicked} />} />
			{portfolioRoutes()}

			<Route path='/rendering' element={<MainRoute route={'rendering'} navigate={navigateClicked} />} />
			<Route path='/rendering2' element={<MainRoute route={'rendering2'} navigate={navigateClicked} />} />

			<Route path='/contact' element={<MainRoute route={'contact'} navigate={navigateClicked} />} />
			<Route path='/admin' element={<MainRoute route={'admin'} navigate={navigateClicked} />} />
		</Routes>
	)
}

const mapStateToProps = (state) => ({
	// dimensions: state.dimensions.dimensions
})
const mapDispatchToProps = (dispatch) => ({
	updateDimensions: (e) => dispatch({type: 'UPDATE_DIMENSIONS',payload: e}),
	changeMobile: (e) => dispatch({type: 'CHANGE_MOBILE',payload: e}),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)












