import React from 'react'

import loaded_imac from '../../Images/loaded/loaded_mac_monitor.png'
import loaded_macbook from '../../Images/loaded/loaded_macbook.png'
import loaded_iphone from '../../Images/loaded/loaded_iphone.png'
import loaded_ipad from '../../Images/loaded/loaded_ipad.png'

const height_multiply = 0.5

const HomePageSectionDev = ({width,dimensions,navigate}) => {

	const height = width*height_multiply

	function size_container(component,position) {
		const className = 'overflow ' + position
		return <div style={{height:height,width:width}} className={className}>{component}</div>
	}

	return(
		<div className='fadeInslow hoverOpacityDownx pointer relative' style={{overflow:'hidden',height:height,width:width}}>
			{size_container(<div className='fullHeight fullWidth ggl_box_backgroundx'
					style={{background:'rgba(255,255,255,1)'}}
					></div>,'absolute pointerNone borderRadius overflow')}

			{size_container(<div className='textAnimateIn2 flexBottom flexCenter' style={{height:height*0.98,width:width,filter:'blur(8px)'}}><div style={{height:30,width:width*0.96,backgroundImage:'radial-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0))'}}></div></div>,'absolute pointerNone borderRadius overflow')}

			{size_container(<div className='textAnimateInBig flexBottom flexCenter' style={{height:height*0.958,width:width*0.62}}><img src={loaded_imac} alt='macs' style={{width:width*0.58}} /></div>,'absolute pointerNone borderRadius overflow')}

			{size_container(<div className='textAnimateInBig flexBottom flexCenter' style={{height:height*0.9,width:width*0.5,marginLeft:width*0.53}}><img src={loaded_ipad} alt='macs' style={{width:width*0.23}} /></div>,'absolute pointerNone borderRadius overflow')}

			{size_container(<div className='textAnimateIn flexBottom flexCenter' style={{height:height*0.98,width:width*0.5,marginLeft:width*0.26}}><img src={loaded_macbook} alt='macs' style={{width:width*0.44}} /></div>,'absolute pointerNone borderRadius overflow')}

			{size_container(<div className='textAnimateIn2 flexBottom flexCenter' style={{height:height*0.92,width:width*0.5,marginLeft:width*0.67}}><img src={loaded_iphone} alt='macs' style={{width:width*0.13}} /></div>,'absolute pointerNone borderRadius overflow')}

			{/*{size_container(<div className='textAnimateIn2 flexCenter ggl_text_heading' style={{height:height*0.24,width:width*0.4,marginLeft:width*0.59}}>
					{width < 1270
						? <div style={{padding:20}}><b className='textGradientLighter'>{"</>"}</b>Dev</div>
						: <div style={{padding:20}}><b className='textGradientLighter'>{"</>"}</b> Devopement</div>
					}
				</div>,'absolute pointerNone borderRadius overflow')}*/}

			<div style={{height:height,width:width,backgroundImage:'radial-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.1))',zIndex:2}} className='overflow absolute flexCenter'>
				<div>
					<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='textGradientLighter noBoldx'>{"</>"}</b> <b className='ggl_textShadow noBold'>Development</b></div>

					{width > 560 ? <div><div className='space_height_10'></div><div className='ggl_text_main_colorx ggl_text_secondary_color textCenter ggl_textShadow'>Using code to find creative solutions to address users needs</div></div> : null }
					<div className='space_height_40'></div>
					<div className='flexCenter flexRightx'>
						<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140,boxShadow:'0px 0px 16px rgba(0,0,0,0.8)',border:'0px solid rgb(120,90,170)'}} onClick={()=>{navigate('projects')}}>Learn more</div>
					</div>
				</div>
			</div>

			{/*<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{}}>
				<div className='textAnimateInBig absolute textCenter'><img src={loaded_imac} alt='macs' style={{width:dimensions.width}} /></div>
				<div className='noOpacity'><img src={loaded_imac} alt='macs' style={{width:dimensions.width}} /></div>
			</div>*/}
		</div>
	)
}

export default HomePageSectionDev