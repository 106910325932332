import React, { useState, useEffect } from 'react'

import Skills from '../Skills/Skills'
// import AudioFileDisplayer from '../AudioPlayers/AudioFileDisplayer'
// import EducationDisplayComponent from '../Education/EducationDisplayComponent'
// import EducationData from '../Data/EducationData'
import Carosel from '../MiniComponents/Carosel'
// import TextAnimationContainer from '../MiniComponents/TextAnimationContainer'
import HomePageSectionMusic from '../Music/HomePageSectionMusic'
import HomePageSectionRender from '../Rendering/HomePageSectionRender'
import HomePageSectionDev from '../Portfolio/HomePageSectionDev'

// import cartoon_avatar from '../../Images/cartoon_avatar.avif'
// import cartoon_liam from '../../Images/cartoon_liam.png'
// import liam_fudge_512 from '../../Images/liam_fudge_512.png'
// import macs from '../../Images/screen_shots/fork_and_spoon_mobile.png'

// const audioData = [
// 	{
// 		album: "",
// 		audio_path: "https://pub-99dd7a047fec4c0fa16396a0cb7e8c14.r2.dev/stem_outputs/_drama_tension_144241827_V1.mp3",
// 		bars: 16,
// 		chord_genre: "tension",
// 		date: "2024-08-28",
// 		folder: "drama_tension_144241827",
// 		generate_type: "song",
// 		genre: "drama_tension",
// 		grid: [0, 16, 32, 48, 64],
// 		id: "drama_tension_144241827",
// 		key: 12,
// 		live: false,
// 		name: "drama_tension_144241827",
// 		roots: [9, 9, 9, 9],
// 		seconds: 114.90133333333333,
// 		tags: [],
// 		tempo: 94,
// 		version: 1,
// 		wave_max: 0.459471165,
// 		wave_shape: [0.19246435, 0.275371345, 0.217560462, 0.104144685, 0.199240825, 0.36927751, 0.21608286, 0.12479773499999999, 0.101332728, 0.387824615, 0.30817236000000003, 0.19495388800000002, 0.1070907775, 0.23964049, 0.358023315, 0.2095068, 0.11726584, 0.1212339425, 0.3544291, 0.284194925, 0.18287303800000002, 0.0966665925, 0.24349055000000003, 0.332898275, 0.23021692, 0.113645192, 0.1058282375, 0.323959205, 0.32328888499999997, 0.1447310825, 0.0916757845, 0.25561508, 0.32434515500000005, 0.21222441, 0.11055838999999999, 0.08758444500000001, 0.33130011, 0.240782775, 0.13437862, 0.115956177, 0.25775447, 0.24544902000000002, 0.219246965, 0.115610923, 0.18214961699999999, 0.369983855, 0.250287755, 0.133226185, 0.120794977, 0.39418854000000003, 0.25905391, 0.20764939, 0.11893276, 0.271089415, 0.347838765, 0.228445285, 0.131576535, 0.11541776, 0.39849718, 0.27558262, 0.20141104999999998, 0.127498647, 0.266408575, 0.31460188, 0.250320865, 0.13640069999999999, 0.1220554525, 0.33565836, 0.308844425, 0.16074682499999998, 0.12416395499999999, 0.14667761, 0.10011148, 0.08150623500000001, 0.07785139999999999, 0.06294758, 0.08632598, 0.08123112, 0.08375149500000001, 0.062347971, 0.0632362875, 0.085113893, 0.082640326, 0.0726424645, 0.075128695, 0.0681774535, 0.09189831300000001, 0.08379042, 0.07847111, 0.05783307, 0.074523953, 0.0708778425, 0.07739969499999999, 0.06919188500000001, 0.0642564435, 0.059402228, 0.07526696, 0.073976297, 0.07027855999999999, 0.089304],
// 	},
// 	{
// 		album: "",
// 		audio_path: "https://pub-99dd7a047fec4c0fa16396a0cb7e8c14.r2.dev/stem_outputs/_drama_tension_396282989_V1.mp3",
// 		bars: 16,
// 		chord_genre: "tension",
// 		date: "2024-08-28",
// 		folder: "drama_tension_396282989",
// 		generate_type: "song",
// 		genre: "drama_tension",
// 		grid: [0, 16, 32, 48, 64],
// 		id: "drama_tension_396282989",
// 		key: 14,
// 		live: false,
// 		name: "drama_tension_396282989",
// 		roots: [9, 9, 9, 9],
// 		seconds: 128.576,
// 		tags: [],
// 		tempo: 84,
// 		version: 1,
// 		wave_max: 0.5015511500000001,
// 		wave_shape: [0.12753719319999998, 0.122700882, 0.3127358, 0.26121941, 0.25432145, 0.23154750000000002, 0.0994483125, 0.068911377, 0.0513430425, 0.28343609999999997, 0.187536245, 0.17288819, 0.185091235, 0.255779325, 0.21469887, 0.0872964725, 0.067847756, 0.0430930125, 0.22171773, 0.237946925, 0.30551103, 0.26633997, 0.24858142500000002, 0.16550335, 0.11630124, 0.0791538775, 0.07502362500000001, 0.260867405, 0.20614347, 0.186725335, 0.16215787, 0.27136472, 0.16397061000000002, 0.08817914, 0.051941434, 0.060143425, 0.25572469, 0.294758405, 0.396079825, 0.35836626, 0.334644345, 0.26954191000000005, 0.21682695000000002, 0.203149575, 0.268016045, 0.31384273, 0.26304689, 0.339446905, 0.3020349, 0.35192588999999996, 0.214450685, 0.188259925, 0.18851568, 0.258060195, 0.2925819, 0.36276088, 0.4132305, 0.33511616, 0.31218224, 0.21065833, 0.23179978499999998, 0.25910939, 0.24414184, 0.29520993500000003, 0.32432672, 0.308182435, 0.260250865, 0.34898466, 0.22107176500000003, 0.222496255, 0.215334305, 0.184245365, 0.11261523, 0.24151744, 0.18324869, 0.18755875, 0.1240381, 0.0648288445, 0.06075092, 0.039290533, 0.0569795, 0.110640295, 0.14428146, 0.16725480999999998, 0.16024439000000001, 0.10357072, 0.11370838750000001, 0.10371586499999999, 0.052836032500000005, 0.0546972845, 0.05682229, 0.20705759, 0.197118935, 0.1510503, 0.11492534700000001, 0.06922668500000001, 0.07747226, 0.052284825, 0.093230385, 0.0959056275,],
// 	}
// ]


const Home = ({dimensions,default_width,navigate}) => {
	const [ loadNum, setLoadNum ] = useState(0)

	useEffect(()=>{
		let count = 0
		let interval = setInterval(()=>{
			if(count > 10){
				clearInterval(interval)
			}
			count+=1
			setLoadNum(count)
		},400)
	},[])

	// function toDos(text,complete) {
	// 	return(
	// 		<div style={{marginLeft:20,marginTop:20,textDecoration:complete === true ? 'line-through' : '',opacity:complete === true ? 0.3 : 1}} className='flexHeightCenter'><div style={{width:6,height:6,borderRadius:'50%',background:'black',marginRight:5,marginTop:2}}></div>{text}</div>
	// 	)
	// }

	// let default_width = 1400
	// if(dimensions.width < 1400 + 120) default_width = dimensions.width - 120
	// if(dimensions.width < 760 + 80) default_width = dimensions.width - 80
	// if(dimensions.width < 600 + 40) default_width = dimensions.width - 40
	// if(dimensions.width < 500) default_width = dimensions.width
	let second_text_width = 720
	if(second_text_width > default_width - 60) second_text_width = default_width - 60
	return(
		<div className='ggl_text_main_secondary'>
			<div className='flexCenter textCenter radialColorBackground homepage_hero_heightx'>
				<div style={{width:default_width-40}}>
					{/*<div style={{height:120}}></div>*/}
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					{/*<TextAnimationContainer />*/}
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>Merging <b className='textGradient'>Tech &</b> Creativity</div>
						<div className='noOpacity'>Merging <b className='textGradient'>Tech &</b> Creativity</div>
					</div>
					<div className='space_height_40'></div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:second_text_width}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:second_text_width}}>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
						<div className='noOpacity'>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
					</div></div>

					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>

			{loadNum > 0
				? <div>
					{/*<div className='flexCenter absolute fadeInslow' style={{zIndex:2,width:dimensions.width,marginTop:-(default_width/7)/2}}>
						<div style={{height:default_width/7,width:default_width/7,borderRadius:100,boxShadow:'0px 0px 16px rgba(0,0,0,1)',border:'2px solid rgb(140,200,240)'}} className='flexCenter overflow'>
							<img src={liam_fudge_512} alt='cartoon_avatar' style={{}} className='fullWidth' />
						</div>
					</div>*/}
					<Carosel width={default_width} dimensions={dimensions} navigate={navigate} />
				</div>
				: <div className='noOpacity'><Carosel width={default_width} dimensions={dimensions} navigate={navigate} /></div>
			}

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='fullWidth ggl_box_backgroundx fadeInslow radialColorBackground'
					// style={{background:'rgb(12,18,26)'}}
					>
				<div className='space_height_60'></div>
				{loadNum > 1
					? <div>
						<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{}}>
							<div className='textAnimateIn absolute textCenter' style={{width:dimensions.width}}>My skillset</div>
							<div className='noOpacity'>My skillset</div>
						</div>
						<div className='space_height_60'></div>
					</div>
					: <div className='noOpacity'><div className='ggl_text_heading ggl_text_main_color overflow relative' style={{}}><div className='noOpacity'>My skills</div></div><div className='space_height_60'></div></div>
				}
				{loadNum > 2
					? <Skills width={default_width} dimensions={dimensions} />
					: <div className='noOpacity'><Skills width={default_width} dimensions={dimensions} /></div>
				}
				<div className='space_height_60'></div>
			</div>

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			{loadNum > 3
				? <div>
					<div className='flexCenter'><HomePageSectionDev width={default_width} dimensions={dimensions} navigate={navigate} /></div>
				</div>
				: <div className='noOpacity'><HomePageSectionDev width={default_width} dimensions={dimensions} navigate={navigate} /></div>
			}
				

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			<div className='textAnimateIn'></div>
			<div className='relative flexCenter'><HomePageSectionRender width={default_width} fullDisplay={false} navigate={navigate} /></div>

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			{/*<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{}}>
				<div className='textAnimateIn absolute textCenter' style={{width:dimensions.width}}>Music I've Produced</div>
				<div className='noOpacity'>Music I've Produced</div>
			</div>*/}
			<div className='textAnimateIn'></div>
			<HomePageSectionMusic width={default_width} dimensions={dimensions} navigate={navigate} />


			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			{/*<div style={{height:1200}}></div>
			Home
			{toDos('Certificates')}
			{toDos('Portfolio')}
			{toDos('Bio')}
			{toDos('3D rendering')}
			{toDos('Music')}
			{toDos('Work History')}

			"Case studies"
			{toDos('allow navigation between the projects and a back button to go to all projects',true)}
			{toDos('Write out the information for all the websites using chatgpt',true)}
			{toDos('consider adding a high quality audio engine one in, and including Hans Zimmer?')}
			{toDos('gather images and different media',true)}
			{toDos('create an object for each case study that can layout and have info on the different elements(mobile picture, audio, video, ect.)')}
			{toDos('store the portfolio simple data and the main data in 2 different parts, so it doesnt slow down loading',true)}


			{toDos('Add example data',true)}
			{toDos('loop through the project routes',true)}
			{toDos('skills graphic',true)}
			{toDos('create portolio container template',true)}
			"Education"
			{toDos('build object for education data',true)}
			{toDos('build a disply component to show an education thing',true)}
			Audio
			{toDos('Play audio',true)}
			{toDos('have one function to control the playback, storing audio in useRef()',true)}
			{toDos('wav shape display',true)}
			{toDos('3 way slider',true)}
			{toDos('skills/passion/experience',true)}
			{toDos('write about each of the skills',true)}
			{toDos('make skills on their side so writing can be horizontal',true)}

			todos
			{toDos('make the slider redux in projects',true)}
			{toDos('make the renders clickable',true)}
			{toDos('allow renders to click back and fourth',true)}
			{toDos('get the size of the modal right with dimensions',true)}
			{toDos('make the music clickable with link',true)}
			{toDos('Layout the portfolio page',true)}
			{toDos('project page banner',true)}
			{toDos('Number of streams',true)}
			{toDos('look for good drafting work',true)}
			{toDos('gradient text color',)}
			{toDos('include andy marsh music in sites',true)}
			{toDos('need social media?',)}
			{toDos('make a cool banner with elements that move as the slider shifts (maybe too processing heavy)',true)}

			<div style={{marginTop:20}}>Tech</div>
			{toDos('Redux')}
			{toDos('Tests')}
			{toDos('performance')}

			<div style={{height:200}}></div>


			<div style={{height:200}}></div>

			<div className='flexCenter'><AudioFileDisplayer data={audioData[0]} width={700} /></div>
			<div className='flexCenter'><AudioFileDisplayer data={audioData[1]} width={700} /></div>
			<div style={{height:200}}></div>
			<div style={{height:200}}></div>*/}

		</div>
	)
}

export default Home