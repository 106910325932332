import React, { useState } from 'react'

import RenderProject from '../Data/RenderProject'
import ProgramCircleDisplayer from '../Portfolio/ProgramCircleDisplayer'

import left_right_chevron from '../../Images/left_right_chevron.svg'

const carosel = ['exterior_render_1','dark_kitchen','sydney_kitchen']

const RenderAllProjectDisplay = ({width,height,padding,navigate}) => {
	const [ wide, setWide ] = useState(0.6)
	// const [ caroselNum, setCaroselNum ] = useState(0)
	const caroselNum = 0

	// function chevronMove(addition) {
	// 	setCaroselNum((caroselNum+3+addition)%3)
	// }


			// const startPos = useRef()
			// function dragLine(e) {
			// 	document.onmousemove = handleMouseMove
			// 	function handleMouseMove(e) {
			// 		if(startPos.current === null || startPos.current === undefined){
			// 			startPos.current = e.clientY
			// 		}
			// 		console.log(e)
			// 		if(e.clientX !== 0){
			// 			// 
			// 		}
			// 	}
			// }



			function cancelDrag() {
				document.onmousemove = killMouse;
				function killMouse(e) {}
			}

	function beginLoopDrag(e) {
		document.onmousemove = handleMouseMove;
		function handleMouseMove(e) {
			const elem = document.getElementById('refBox')
			const rect = elem.getBoundingClientRect()
			let percent = (e.clientX - rect.left) / rect.width
			if(percent > 0.99) percent = 0.99
			if(percent < 0.01) percent = 0.01

			setWide(percent)
		}
	}


	function size_container(component,position,minus) {
		const className = 'overflow ' + position
		return <div style={{height:height-minus,width:width-minus,marginLeft:(minus/2),marginTop:(minus/2)}} className={className}>{component}</div>
	}

	// function isCircleFilled(num) {
	// 	if(num !== caroselNum) return null
	// 	return <div style={{height:10,width:10,borderRadius:20,background:'white'}}></div>
	// }

	if(width < 1200){
		return(
			<div style={{width:width,height:height}} className='overflow relative ggl_border borderRadius flexCenter'>
				{/*{size_container(<div className='fullHeight fullWidth ggl_box_background'></div>,'absolute pointerNone',0)}*/}
				{size_container(<div style={{height:height,width:width}} className='overflow flexCenter'><img src={RenderProject[carosel[caroselNum]]['src']} alt='backgroundImage' style={{filter:'blur(2px)',height:height*1.2}} /></div>,'absolute borderRadius ggl_border',0)}

				{size_container(<div className='flexCenter' style={{backgroundImage:'radial-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.5))',height:height,width:width}}>
						<div style={{width:width*0.8}}>
							<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='noBold ggl_textShadow'>Architectural</b> <b className='textGradientLighter noBold'>visualizations</b></div>
							<div className='space_height_10'></div>
							<div className='ggl_text_main_colorx ggl_text_secondary_color textCenter ggl_textShadow'>I have worked with architects and landscape designers to bring ideas to life</div>
							<div className='space_height_40'></div>
							<div className='flexCenter flexRightx'>
								<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('rendering')}}>Learn more</div>
							</div>
						</div>
					</div>,'absolute pointerNonex',0)}

			</div>
		)
	}
	return(
		<div style={{width:width,height:height}} className='overflow relative ggl_border borderRadius' onMouseUp={cancelDrag} onMouseLeave={cancelDrag} id={'refBox'}>
			{size_container(<div className='fullHeight fullWidth ggl_box_background'></div>,'absolute pointerNone',0)}

			{size_container(<div style={{width:width-padding}} className='overflow'><img src={RenderProject[carosel[caroselNum]]['model']} alt='backgroundImage' style={{width:width-padding,filter:'blur(0px)',opacity:0.5}} /></div>,'absolute flexCenter disableSelect borderRadius ggl_borderx',padding)}
			
			{/*{size_container(
					<div style={{width:width*wide,height:height,borderRight:'1px solid white',boxShadow:'0px 0px 15px rgba(230,220,255,0.3)'}} className='overflow relative flexRightx'>
						{size_container(<img src={RenderProject[carosel[caroselNum]]['src']} alt='backgroundImage' style={{width:width,filter:'blur(0px)'}} />,'absolute flexCenter borderRadius ggl_border',padding)}
					</div>
				,'absolute flexCenterx disableSelect borderRadius',padding)}*/}
			{size_container(<div style={{width:(width-padding)*wide,borderRight:'1px solid white'}} className='overflow'><img src={RenderProject[carosel[caroselNum]]['src']} alt='backgroundImage' style={{width:(width-padding),filter:'blur(0px)'}} /></div>,'absolute flexHeightCenter borderRadius ggl_borderx',padding)}

			{/*{size_container(<div style={{width:width*0.4}} className='flexCenter'>
					<div style={{width:80}}></div>
					<div style={{padding:30,backdropFilter:'blur(2px)',boxShadow:'0px 0px 16px rgba(0,0,0,0.4)'}} className='borderRadius ggl_borderx'>
						<div className='ggl_text_heading ggl_text_main_color textCenter'>Architectural visualizations</div>
						<div style={{height:20}}></div>
						<div className='ggl_text_main_color textCenter'>I have worked with architects and landscape designers to bring ideas to life</div>
						<div style={{height:40}}></div>
						<div className='flexCenter flexRightx relativex' style={{zIndex:40000}}>
							<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
						</div>
					</div>
					
				</div>,'absolute flexRightx flexLeft disableSelectx borderRadius ggl_borderx',0)}*/}

			{size_container(<div className='absolute disableSelect pointerNone flexCenter'
						style={{marginLeft:(width-padding)*wide-11,marginTop:(height)/2-12,boxShadow:'0px 0px 12px rgba(0,0,0,0.6)',height:22,width:22,background:'white',borderRadius:'50%'}}>
					<img src={left_right_chevron} alt='left_right_chevron' style={{height:14}} className='pointerNone' />
				</div>,'absolute flexHeightCenterx borderRadius ggl_borderx',padding)}


			{size_container(<div className='absolute' style={{marginLeft:(width-padding)*wide-10,cursor:'grab',width:20,height:height,zIndex:10}} onMouseDown={beginLoopDrag}></div>,'absolute flexHeightCenterx borderRadius ggl_borderx',padding)}

			{size_container(<div style={{width:width*0.4}} className='flexCenter'>
					<div style={{width:80}}></div>
					<div style={{padding:30,backdropFilter:'blur(2px)',boxShadow:'0px 0px 16px rgba(0,0,0,0.4)'}} className='borderRadius ggl_borderx'>
						<div className='ggl_text_heading ggl_text_main_color textCenter'>Architectural visualizations</div>
						<div style={{height:20}}></div>
						<div className='ggl_text_main_color textCenter'>I have worked with architects and landscape designers to bring ideas to life</div>
						<div style={{height:40}}></div>
						<div className='flexCenter flexRightx relativex' style={{zIndex:40000}}>
							<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('rendering')}}>Learn more</div>
						</div>
					</div>
					
				</div>,'absolute flexRightx flexLeft disableSelectx borderRadius ggl_borderx',0)}
			

			{/*<div className='absolute' style={{marginLeft:width*wide-10,cursor:'grab',width:20,height:height,zIndex:10,border:'1px solid red'}} onMouseDown={beginLoopDrag}></div>*/}

			{size_container(<div style={{padding:30}}><ProgramCircleDisplayer width={width} dimensions={{height:40,width:500}} type={{'current':2,}} /></div>,'absolute flexBottom pointerNone',0)}
		</div>
	)
}

export default RenderAllProjectDisplay